<template>
  <page-layout>
    <template
      v-slot:topBar
    >
      <search-top-bar
        @submit="submitSearch"
        :loading="loading"
        :search-suggestions-list="searchSuggestionsList"
        :error-indexes="errorIndexes"
      />
    </template>
    <search-page-body
      @search-from-history="searchFromHistory"
      :search-history="searchHistory"
    >
      {{ messages.getLabelSearchForStatus() }}
    </search-page-body>
  </page-layout>
</template>

<script>
import { messages } from '@/utils/strings'
import SearchPageBody from '../components/Search/SearchPageBody'
import SearchTopBar from '../components/Search/SearchTopBar'
import PageLayout from '../components/PageLayout'
import { statusMonitorSearchHistory } from '@/utils/search-history'
import { listTrackingStatuses, updateRouteQueryParameters } from '@/utils/search'
import { searchInput, formatArraySearchString } from '@/utils/format-search-string'
import { MOBILE_MAX_RESULTS } from '@/constants/shared-constants'

const MONITOR_SEARCH_INPUTS = {
  access_point_phone_number: searchInput(['access point phone number', 'accesspointphonenumber', 'apn', 'access_point_phone_number']),
  address_code: searchInput(['address code', 'addresscode', 'ac', 'address_code']),
  carrier: searchInput(['carrier', 'car']),
  created: searchInput(['created', 'cr']),
  delivery_address: searchInput(['delivery address', 'deliveryaddress', 'da', 'delivery_address']),
  delivery_order_doc_number: searchInput(['delivery order', 'deliveryorder', 'do', 'delivery_order']),
  delivery_status: searchInput(['delivery status', 'deliverystatus', 'ds', 'delivery_status']),
  equipment_code: searchInput(['equipment code', 'equipmentcode', 'ec', 'equipment_code']),
  equipment_description: searchInput(['equipment description', 'equipmentdescription', 'eqd', 'equipment_description']),
  material_code: searchInput(['material code', 'materialcode', 'mc', 'material_code']),
  material_description: searchInput(['material description', 'materialdescription', 'md', 'material_description']),
  planner_group: searchInput(['planner group', 'plannergroup', 'pg', 'planner_group']),
  plant: searchInput(['plant', 'pl', 'plant']),
  purchase_order_doc_number: searchInput(['purchase order', 'purchaseorder', 'po', 'purchase_order_doc_number']),
  reservation_doc_number: searchInput(['reservation', 'res', 'reservation_doc_number']),
  sales_order_doc_number: searchInput(['sales order', 'salesorder', 'so', 'sales_order_doc_number']),
  service_order: searchInput(['service order', 'serviceorder', 'so', 'service_order']),
  ship_to_party: searchInput(['ship to party', 'shiptoparty', 'stp', 'ship_to_party']),
  storage_location: searchInput(['storage location', 'storagelocation', 'sloc', 'storage_location']),
  tracking_number: searchInput(['tracking number', 'trackingnumber', 'tn', 'tracking_number']),
  transport_details: searchInput(['transport details', 'transportdetails', 'td', 'transport_details']),
  work_center: searchInput(['work center', 'workcenter', 'wc', 'work_center'])
}
export default {
  name: 'SearchStatusMonitor',
  components: { PageLayout, SearchPageBody, SearchTopBar },
  data () {
    return {
      loading: false,
      searchSuggestionsList: [
        'Access Point Phone Number',
        'Address Code',
        'Carrier',
        'Created',
        'Delivery Address',
        'Delivery Order',
        'Delivery Status',
        'Equipment Code',
        'Equipment Description',
        'Material Code',
        'Material Description',
        'Planner Group',
        'Plant',
        'Purchase Order',
        'Reservation',
        'Sales Order',
        'Service Order',
        'Ship to Party',
        'Storage Location',
        'Tracking Number',
        'Transport Details',
        'Work Center'
      ],
      errorIndexes: [],
      messages
    }
  },
  created () {
    this.$emit('set-background-color', '#F4F4F4')
  },
  computed: {
    uid () {
      return this.$store.state.profile.uid
    },
    searchHistory: function () {
      // This most likely does not automatically be recalculated when
      // addSearchToHistory is called. However since the view changes
      // back to StatusMonitor, when the user navigates back to this
      // view this computed property will get triggered and updated.
      return statusMonitorSearchHistory.retrieve()
    }
  },
  methods: {
    submitSearch (searchValue, performSearch) {
      searchValue = searchValue.filter(value => value !== '')
      let searchText = ''
      const { searchDict, errorIndexes } = formatArraySearchString(MONITOR_SEARCH_INPUTS, searchValue)
      this.errorIndexes = errorIndexes
      // for each value in searchDict remove first char
      Object.keys(searchDict).forEach(key => {
        searchDict[key] = searchDict[key].slice(1)
        if (key === 'search') {
          searchText = searchDict[key].replaceAll(',', ' ')
          delete searchDict[key]
        }
      })
      const queryParameters = {
        query: searchText,
        page: 1,
        maxResults: MOBILE_MAX_RESULTS
      }
      if (performSearch) {
        this.$store.dispatch('statusMonitor/search', { ...queryParameters, extraParams: searchDict })
        // Go to the results page (Main Status Monitor page) before the response arrives
        // to get rid of annoying delay caused by route change. I don't know why that
        // delay is there.
        this.$router.push({ name: 'StatusMonitor' })
      }
    },
    async getTrackingStatus (searchValue) {
      // FIXME: This is currently not used because of the weird search logic...
      // console.log('Get status monitor data')
      this.loading = true
      try {
        const listing = await listTrackingStatuses({
          query: searchValue, // TODO: How does the search work?
          page: this.page,
          uid: this.uid,
          filters: {} // FIXME: Implement filters?
        })
        const response = listing.response
        const urlQuery = listing.urlQuery
        this.trackingStatusItems = response.data.trackingStatus
        this.page = response.data.page
        this.$store.commit('setTrackingStatusCounts', response)
        updateRouteQueryParameters({ router: this.$router, query: this.$router.currentRoute.query, urlQuery })
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    /**
     * Use text showed in badges and stored in local storage for searching
     *
     * @param {Object} searchEntry -
     */
    searchFromHistory (searchEntry) {
      let searchText = ''
      const searchArray = searchEntry.searchText.split('&')
      const { searchDict } = formatArraySearchString(MONITOR_SEARCH_INPUTS, searchArray)
      Object.keys(searchDict).forEach(key => {
        searchDict[key] = searchDict[key].slice(1)
        if (key === 'search') {
          searchText = searchDict[key].replaceAll(',', ' ')
          delete searchDict[key]
        }
      })
      const queryParameters = {
        query: searchText,
        page: 1,
        maxResults: MOBILE_MAX_RESULTS
      }
      this.$store.dispatch('statusMonitor/search', { ...queryParameters, extraParams: searchDict })
      // Go to the results page (Main Status Monitor page) before the response arrives
      // to get rid of annoying delay caused by route change. I don't know why that
      // delay is there.
      this.$router.push({ name: 'StatusMonitor' })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
