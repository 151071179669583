import axios from 'axios'
import { STATUS_ARRIVED, STATUS_INCOMING } from '@/constants/shared-constants'

async function listTrackingStatuses ({ query, page, filters, uid, maxResults }) {
  if (!maxResults) {
    maxResults = 20
  }
  // save filters in url query
  const urlQuery = { query: query ?? '', page: page ?? 1 }
  if (filters.status === STATUS_ARRIVED || filters.status === STATUS_INCOMING) {
    urlQuery.status = filters.status
  }
  if (filters.myOrders) {
    urlQuery.myOrders = true
  }
  const searchParams = new URLSearchParams()
  const entries = Object.entries(urlQuery)
  for (const [name, value] of entries) {
    searchParams.append(name, value)
  }
  searchParams.append('maxResults', maxResults)
  let url = `${process.env.VUE_APP_PARTS_API_ENDPOINT}/parts/v1/users/${uid}/tracking-status?`
  url += searchParams.toString()
  const response = await axios.get(url)
  // console.log(response.data)
  return { response, urlQuery }
}

function updateRouteQueryParameters ({ router, query, urlQuery }) {
  // Disabled for now: See https://verkkoaps.atlassian.net/browse/CPL-379
  /* eslint-disable no-useless-return */
  return
  /*
  // update route query only if it has changed
  if (JSON.stringify(query) !== JSON.stringify(urlQuery)) {
    router.push({
      name: 'StatusMonitor',
      query: urlQuery
    })
  }
  */
}

export {
  listTrackingStatuses,
  updateRouteQueryParameters
}
